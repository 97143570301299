<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
               <div class="btn-group w-100" role="group">
                   <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="far fa-newspaper mr-2" v-show="platform == 'desktop'"></i>
                        <span>Criar</span>
                   </button>
                   <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="fas fa-sack-dollar mr-2" v-show="platform == 'desktop'"></i>
                        <span>Bolões</span>
                        <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile', 'mt-1': platform == 'desktop'}" v-show="cat == 2 && accumulatedEvents.length > 0">{{accumulatedEvents.length}}</span>
                   </button>
               </div><!-- /btn-group -->
                <div v-show="loading == true" class="alert alert-warning mt-3" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="cat == 1 && nothing_found == true" class="alert alert-warning mt-3" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">Não encontramos nenhum evento disponível!</p>
                </div><!-- /alert-warning -->
                <div v-show="error.length > 0" class="alert alert-danger mt-3" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                    <hr />
                    <div class="row ml-0 mt-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                                <option value="1" selected>Aberto</option>
                                <option value="2">Finalizado</option>
                            </select>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="getAccumulated"><i class="fas fa-search mr-2"></i>Buscar</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                </div><!-- /alert-danger -->
                <div class="mt-3 mb-3">
                   <template v-if="cat == 1">
                        <form @submit.prevent="newAccumulated">
                            <div class="form-group">
                                <label for="title" class="text-light">Título</label>
                                <input type="text" class="form-control" v-model="accumulated.title" @keypress="updateOptionValue($event)" id="title" placeholder="Ex: Acumuladão 01" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="value" class="text-light">Valor</label>
                                <input type="text" class="form-control" v-model="accumulated.value" @keypress="IsNumber($event)" id="value" placeholder="Ex: 5" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="premium" class="text-light">Prêmiação</label>
                                <input type="text" class="form-control" v-model="accumulated.premium" @keypress="IsNumber($event)" id="premium" placeholder="Ex: 10000" autocomplete="off" spellcheck="false" required>
                            </div>
                            <div class="form-group">
                                <label for="number-games" class="text-light">Número de jogos</label>
                                <select class="form-control" id="number-games" v-model="accumulated.number_games">
                                    <option :value="i" v-for="i in 10" :key="i">{{i}}</option>
                                </select>
                            </div>
                            <template v-if="events.length">
                                <div class="form-group">
                                    <label for="league" class="text-light">Selecione uma liga</label>
                                    <select class="form-control" id="league" v-model="league">
                                        <option v-for="(item, index) in leaguesOrganizes(events)" :key="index" :value="item.liga_id">{{item.liga}}</option>
                                    </select>
                                </div>
                                <template v-if="league">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-dark">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Time da casa</th>
                                                    <th scope="col">Time de fora</th>
                                                    <th scope="col">Horário</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in filteredListEvents" :key="index">
                                                    <th scope="row">
                                                        <a class="btn btn-sm btn-block" @click="add(item.id)" :class="[isSelected(item.id) ? 'btn-danger' : 'btn-secondary']"><i :class="[isSelected(item.id) ? 'fas fa-minus-circle' : 'fas fa-plus-circle']"></i></a>
                                                    </th>
                                                    <td class="text-truncate align-middle">{{item.time_casa}}</td>
                                                    <td class="text-truncate align-middle">{{item.time_fora}}</td>
                                                    <td class="text-truncate align-middle">{{item.horario | formatDateDDMMHHMM}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0 pr-0" colspan="12">
                                                        <div class="row m-0 p-0">
                                                            <div class="col pr-0">
                                                                <a class="btn btn-block btn-info" :class="{'disabled': accumulated.events.length == 0}" @click="viewSelectedGames">
                                                                    <span>Jogos selecionados</span> 
                                                                    <span class="badge badge-light ml-2">{{accumulated.events.length}}</span>
                                                                </a>
                                                            </div>
                                                            <div class="col">
                                                                <a class="btn btn-block btn-danger" :class="{'disabled': accumulated.events.length == 0}" @click="clearGames">
                                                                    <i class="fas fa-trash mr-2"></i>
                                                                    <span>Remover jogos</span> 
                                                                </a>                                                                
                                                            </div>
                                                        </div> 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                            </template>
                            <div class="row" :class="{'pl-3 pr-3': platform == 'mobile'}">
                                <div class="col pr-0" :class="{'d-contents': platform == 'mobile'}">
                                    <a class="btn btn-warning btn-block" :class="{'mb-3': platform == 'mobile'}" @click="getEvents">
                                        <i class="fas fa-sync-alt mr-2"></i>
                                        <span>Carregar jogos</span>
                                    </a>
                                </div>
                                <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                    <button type="submit" class="btn btn-primary btn-block mb-3" :disabled="loading == true">
                                        <i class="fas fa-check-circle mr-2"></i>
                                        <span>Criar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                   </template>
                   <template v-else>
                       <div class="card mb-3" v-for="(item, index) in accumulatedEvents" :key="index">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-8 text-truncate">{{item.titulo}}</div>
                                    <div class="col-4 text-truncate text-right">{{item.horario | formatDate}}</div>
                                </div>
                            </div><!-- /card-header -->
                            <div class="card-body">
                                <div class="row border-bottom pb-2">
                                    <div class="col text-truncate">Valor</div>
                                    <div class="col text-truncate text-right">{{item.valor | formatCoin}}</div>
                                </div>
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Premiação</div>
                                    <div class="col text-truncate text-right">{{item.premiacao | formatCoin}}</div>
                                </div>
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Número de jogos</div>
                                    <div class="col text-truncate text-right">{{item.num_jogos}}</div>
                                </div>
                                <div class="row border-bottom pt-2 pb-2">
                                    <div class="col text-truncate">Número de ganhadores</div>
                                    <div class="col text-truncate text-right">{{item.total_ganhadores}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-12 p-0 text-truncate">
                                        <div class="pl-3 pr-3 pt-2 pb-2 bg-secondary text-light rounded">Jogos</div>
                                    </div>
                                </div><!-- /row -->
                                <div class="row mt-2" v-for="(event, i) in orderAccumulated(item.eventos)" :class="{'border-bottom pb-2': i < item.eventos.length - 1}" :key="i">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col text-truncate">{{event.time_casa}}</div>
                                            <div class="col-auto text-center">{{event.placar_casa}}:{{event.placar_fora}}</div>
                                            <div class="col text-truncate text-right">{{event.time_fora}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col text-truncate text-info">{{event.liga}}</div>
                                            <div class="col-auto text-center"><i class="fal fa-arrows-h"></i></div>
                                            <div class="col text-truncate text-info text-right">{{event.horario | formatDate}}</div>
                                        </div>
                                    </div>
                                </div><!-- /row -->
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <div class="w-100" :class="{'btn-group': platform == 'desktop', 'btn-group-vertical': platform == 'mobile'}" role="group">
                                            <button class="btn btn-danger" :class="{'mb-2 rounded-bottom': platform == 'mobile'}" @click="deletAccumulated(item._id)">
                                                <i class="fas fa-trash mr-2"></i>Excluir
                                            </button>
                                            <button class="btn btn-dark" :class="{'mb-2 rounded-top rounded-bottom': platform == 'mobile'}" @click="locksAndUnlocks(item._id, item.ativo)">
                                                <i class="mr-2" :class="{'fas fa-lock': item.ativo == 1, 'fas fa-unlock': item.ativo == 2}"></i>{{item.ativo == 1 ? 'Bloquear' : 'Desbloquear'}}
                                            </button>
                                        </div><!-- /btn-group -->
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /card-body -->                  
                       </div>
                   </template>
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 1,
            status: 1,
            error: '',
            events: [],
            league: '',
            loading: false,
            isLoading: false,
            nothing_found: false,
            accumulatedEvents: [],
            accumulated: {
                title: '',
                events: [],
                value: '',
                premium: '',
                number_games: 4,
            },
            platform: window.screen.width > 600 ? 'desktop' : 'mobile',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
			}
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        getEvents() {
            
            const self = this;

            self.events = [];
            self.clearGames();
            self.isLoading = true;
            self.nothing_found = false;

            api.get('dashboard/accumulated/events').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = response.data.events.sort((a, b) => {
                            
                            if (self.removeAccents(a.liga) < self.removeAccents(b.liga)) return -1;
                            if (self.removeAccents(a.liga) > self.removeAccents(b.liga)) return 1;

                            if (a.horario < b.horario) return -1;
                            if (a.horario > b.horario) return 1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        getAccumulated() {

            const self = this;

            self.error = '';
            self.loading = true;
            self.accumulatedEvents = [];

            api.get(`dashboard/accumulated/status/${self.status}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.accumulatedEvents = response.data.events;
                    break;
                    case 'nothing_found':
                        self.status = 1;
                        self.error = 'Não encontramos nenhum desafio!';
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        add(id) {
            
            const index = this.accumulated.events.findIndex(item => item.id == id);

            if (index != -1) {
                this.accumulated.events.splice(index, 1);
            }
            else
            {
                const event = this.events.find(item => item.id == id);

                if (event instanceof Object) {

                    if (this.accumulated.events.length < this.accumulated.number_games) {
                        this.accumulated.events.push({
                            id: event.id,
                            liga: event.liga,
                            horario: event.horario,
                            time_casa: event.time_casa,
                            time_fora: event.time_fora,
                            time_casa_id: event.time_casa_id,
                            time_fora_id: event.time_fora_id,
                            imagens: event.imagens,
                            placar_casa: 0,
                            placar_fora: 0
                        });
                    }
                    else
                    {
                        Swal.fire(
                            'Atenção',
                            `Selecione no máximo ${this.accumulated.number_games} jogos`,
                            'warning'
                        );
                    }
                }
                else
                {
                    Swal.fire(
                        'Atenção',
                        'Não foi possível encontrar informações sobre essa partida, por favor, atualize a página e tente novamente!',
                        'warning'
                    );                    
                }
            }
        },
        clearGames() {
            this.accumulated.events = [];
        },
        isSelected(id) {

            if (this.accumulated.events.some(item => item.id == id)) {
                return true;
            } else {
                return false;
            }
        },
        viewSelectedGames() {

            if (this.accumulated.events.length == 0) {
                Swal.fire(
                    'Atenção',
                    'Nenhum jogo selecionado!',
                    'warning'
                );
            } else {

                let html = ``;

                this.accumulated.events.forEach((item) => {
                    html += `
                        <div class="row pt-2 pb-2 border-bottom">
                            <div class="col text-truncate text-center">${item.time_casa}</div>
                            <div class="col-auto text-center">
                                <i class="fal fa-times"></i>
                            </div>
                            <div class="col text-truncate text-center">${item.time_fora}</div>
                        </div>
                    `;
                });

                Swal.fire(
                    `${this.accumulated.events.length == 1 ? 'Jogo selecionado' : 'Jogos selecionados'}`,
                    html
                );
            }
        },
        newAccumulated() {
            
            const self = this;

            if (self.accumulated.title.trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o título do acumuladão!',
                    'warning'
                );                
            } else if (self.accumulated.value == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor mínimo da aposta!',
                    'warning'
                );                       
            } else if (self.accumulated.premium == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o prêmio do acumuladão!',
                    'warning'
                );                       
            } else if (self.accumulated.events.length == 0) {
                Swal.fire(
                    'Atenção!',
                    `Selecione ${self.accumulated.events.length} jogos, para poder continuar!`,
                    'warning'
                );  
            } else if (self.accumulated.events.length > self.accumulated.number_games) {
                Swal.fire(
                    'Atenção!',
                    `Selecione no máximo ${self.accumulated.number_games} jogos, para poder continuar!`,
                    'warning'
                );                  
            } else {

                Swal.fire({
                    title: 'Confirmação',
                    html: 'Você deseja mesmo criar esse acumuladão?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.post('dashboard/accumulated', {
                            schedule: self.minDate(),
                            accumulated: self.accumulated
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.accumulated.title = '';
                                    self.accumulated.events = [];
                                    self.accumulated.premium = '';

                                    Swal.fire(
                                        'Parabéns!',
                                        'Seu acumuladão foi criado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    )
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        locksAndUnlocks(id, value) {

            const self = this;
            const type = value == 2 ? 1 : 2;
            const label = value == 1 ? 'Bloquear' : 'Desbloquear';
            const event = self.accumulatedEvents.find((obj => obj._id == id));

            Swal.fire({
				title: `${label} bolão`,
				html: `Você deseja mesmo ${label.toLowerCase()} <p class="mb-0"><b>"${event.titulo}"</b> ?</p>`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    Swal.fire({
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        text: 'Salvando alterações...',
                        onBeforeOpen: () => {
                            Swal.showLoading()  
                        }
                    });

                    api.put(`dashboard/accumulated/lock-unlock-event/${id}/${type}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                event.ativo = type;

                                Swal.fire(
                                    'Parabéns!',
                                    `O bolão foi ${type == 2 ? 'bloqueado' : 'debloqueado'} com sucesso!`,
                                    'success'
                                );
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'warning'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'warning'
                            );
                        }
                    });
                }
            });
        },
        deletAccumulated(id) {

            const self = this;
            const index = self.accumulatedEvents.findIndex(item => item._id == id);

            if (index != -1) {

                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo excluir o bolão <b>"${self.accumulatedEvents[index].titulo}"</b> ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.delete(`dashboard/accumulated/${id}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.accumulatedEvents.splice(index, 1);

                                    Swal.fire(
                                        'Parabéns!',
                                        'Seu acumuladão foi excluído com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'warning'
                                    )
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
            else
            {
                Swal.fire(
                    'Atenção!',
                    'Não foi possível excluir esse bolão!',
                    'error'
                );
            }
        },
        minDate() {
            
            let dates = this.accumulated.events.map((item) => {
                return new Date(item.horario);
            });

            return new Date(Math.min.apply(null, dates));
        },
        removeAccents(str) {

            let string = str;
            let map = {
                a : /[\xE0-\xE6]/g,
                A : /[\xC0-\xC6]/g,
                e : /[\xE8-\xEB]/g,
                E : /[\xC8-\xCB]/g,
                i : /[\xEC-\xEF]/g,
                I : /[\xCC-\xCF]/g,
                o : /[\xF2-\xF6]/g,
                O : /[\xD2-\xD6]/g,
                u : /[\xF9-\xFC]/g,
                U : /[\xD9-\xDC]/g,
                c : /\xE7/g,
                C : /\xC7/g,
                n : /\xF1/g,
                N : /\xD1/g,
            };

            for (let key in map) {
                let rex = map[key];
                string = string.replace(rex, key);
            }

            return string;
        },
        IsNumber(event) {
            if (!/\d/.test(event.key)) return event.preventDefault();
        },
        updateOptionValue(event) {
           event.target.value = event.target.value.replace(/[^a-zà-ú0-9\\!?.,-\s]+/gi,'');
        },
        leaguesOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.liga_id === o.liga_id)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.liga.localeCompare(b.liga)
            });
        },
        orderAccumulated(events) {
            return events.slice().sort((a, b) => {
                return (a.horario > b.horario) ? 1 : -1;
            });
        }
    },
    watch: {
        cat(value) {

            this.error = '';

            if (value == 2) {
                this.getAccumulated();
            }            
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY - HH:mm');
        },
        formatDateDDMMHHMM(date) {
            return moment(date).tz('America/Recife').format('DD/MM HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
    },
    computed: {
        filteredListEvents() {
            return this.events.filter((item) => {
                return item.liga_id == this.league
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
</style>